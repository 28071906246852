/* eslint-disable @typescript-eslint/ban-ts-comment */
// Make sure to register before importing any components
import './class-component-hooks';

import { domainHelper, VueApiPlugin } from '@homeserve/vue-api-plugin';
import VueAuthPlugin from '@homeserve/vue-auth-plugin';
import ODComponentsCore from '@homeserve/vue-components-plugin/src/main';
import { formatPhone } from '@homeserve/vue-components-plugin/src/utils/phoneHelper';
import { partnerFilterWrapper } from '@homeserve/vue-components-plugin/src/utils/priceHelper';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import 'core-js/stable';
import 'material-design-icons-iconfont';
import moment from 'moment';
import VeeValidate from 'vee-validate';
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesFR from 'vee-validate/dist/locale/fr';
import Vue from 'vue';
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueLazyload from 'vue-lazyload';
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import Vuetify from 'vuetify/lib';

import { version } from '../package.json';
import App from './App.vue';
import './directives/StickyFooter';
import { http } from './http-client';
import i18n from './lang';
import router from './router';
import store from './store';
import './styles/fonts.css';
import './styles/global.css';
import './styles/override.styl';
import theme from './styles/theme';
import { formatDate, updateLocale } from './utils/dateHelpers';
import { cleanOldLocalStorageVersions } from './utils/localstorageHelper';

updateLocale();
moment.locale('fr');
cleanOldLocalStorageVersions(version);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    release: ['staging', 'production'].includes(process.env.VUE_APP_SENTRY_ENV) ? version : undefined,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENV,
    logErrors: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
    tracingOptions: {
      trackComponents: true,
    },
  });
}
Vue.use(VueRouter);
Vue.use(VueLazyload);
Vue.use(VueFriendlyIframe);
Vue.use(VeeValidate, {
  inject: false,
  i18n,
  dictionary: {
    en: validationMessagesEn,
    fr: validationMessagesFR,
  },
});

Vue.use(Vuetify, {
  iconfont: 'md', // 'md' || 'mdi' || 'fa' || 'fa4'
  theme,
  options: {
    customProperties: true,
    minifyTheme: (css: string) => {
      return process.env.NODE_ENV === 'production' ? css.replace(/[\s|\r\n|\r|\n]/g, '') : css;
    },
  },
});
Vue.config.productionTip = false;

export function isNotABot() {
  return (
    window.navigator.userAgent.indexOf('Chrome-Lighthouse') === -1 &&
    window.navigator.userAgent.indexOf('Googlebot') === -1
  );
}
Vue.mixin({
  computed: {
    isNotABot,
  },
});

const gtmConfig = {
  id: 'GTM-P9WKWZ4',
  enabled: isNotABot(),
  debug: process.env.NODE_ENV === 'development',
};

// TODO: use proper type once they are exported from the components plugin
const pluginsInstances: any = { i18n };

Vue.use(VueScrollTo);

Vue.use(ODComponentsCore, {
  gtmConfig,
  pluginsInstances,
});

Vue.use(VueAuthPlugin, {
  auth0: {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CLIENTID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    responseType: 'token',
    scope: 'profile email',
    redirectUri:
      `${location.protocol}//${location.hostname}:${location.port}/callback` || 'http://localhost:8080/callback',
    clientCredentialUri: process.env.VUE_APP_TENANT_URL + 'authenticateApp/SAUR',
  },
  http,
  tenantUrl: process.env.VUE_APP_TENANT_URL + 'authenticateApp',
});

Vue.use(VueApiPlugin, { http });

Vue.filter('formatPhone', formatPhone);
Vue.filter('formatDate', formatDate);
Vue.filter('formatPrice', partnerFilterWrapper(store.getters.partner).formatPrice);
Vue.filter('formatVoucher', partnerFilterWrapper(store.getters.partner).formatVoucher);
if (isNotABot()) {
  const vm = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
  });

  (async () => {
    router.onReady(async () => {
      vm.$mount('#app');
      // @ts-ignore
      if (window.customProp) {
        // @ts-ignore
        domainHelper.setDomains(vm.$store, window.customProp.domains);
      } else {
        await domainHelper.fetchDomains(vm.$store);
      }
    });
  })();
}
