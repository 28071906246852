var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(_vm.authFailed)?_c('div',[_c('WLAuthFailed')],1):_c('v-content',{class:{
      'custom-container': !_vm.$route.meta.noHeader && !_vm.isOurServiceLanding,
    },style:({
      'margin-bottom': _vm.stickyFooterHeight ? `${_vm.stickyFooterHeight}px` : 0,
    })},[(!_vm.$route.meta.noHeader)?[(_vm.isOurServiceLanding)?_c('Header',[_c('v-layout',{attrs:{"align-center":"","justify-space-between":_vm.$vuetify.breakpoint.mdAndUp,"justify-center":_vm.$vuetify.breakpoint.smAndDown,"row":""}},[_c('router-link',{staticClass:"pa-2 text-xs-center",staticStyle:{"width":"200px"},attrs:{"to":"/"}},[_c('WLBrand')],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-layout',{attrs:{"row":""}},[_c('img',{attrs:{"src":require("./assets/our-service/picto-tel.svg")}}),_c('v-layout',{staticClass:"ml-2 secondary--text",attrs:{"column":""}},[_c('a',{staticClass:"display-2 secondary--text",staticStyle:{"text-decoration":"none"},attrs:{"href":"tel:+33180202377"}},[_c('span',{staticClass:"bold"},[_vm._v("01 80 20 23 77")])]),_c('div',[_vm._v("Service gratuit + coût de l'appel")])])],1)],1):_vm._e()],1)],1):_c('WLHeader',{attrs:{"topNavLinks":_vm.topNavLinks,"navLinks":_vm.navLinks,"overrideI18n":{
          nav_number: _vm.navNumber,
          navNumberFees: _vm.i18n.header.navNumberFees,
          nav_phone_number_link: _vm.phoneNumberLink,
        },"logo":{
          src: require('@/assets/saur-recommande-homeserve.svg'),
          width: _vm.$vuetify.breakpoint.lgAndUp ? 350 : 210,
        }}})]:_vm._e(),_c('transition',{attrs:{"enter-active-class":"animated fadeIn faster","leave-active-class":"animated fadeOut faster","mode":"out-in"}},[_c('router-view',{staticClass:"router-view"})],1),(!_vm.$route.meta.noFooter)?_c('Footer'):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }