import { formatPhone } from '@homeserve/vue-components-plugin/src/utils/phoneHelper';

export const messages = {
  common: {
    email: 'Adresse email',
    affiliation_link: "Lien d'affiliation",
    actions: 'Actions',
    created_at: 'Créé le',
    date: {
      from: 'A partir du',
      to: `Jusqu'au`,
      start_week_day: 'lundi',
      end_week_day: 'vendredi',
      start_week_end_day: 'samedi',
      end_week_end_day: 'dimanche',
    },
    default_error: 'Une erreur est survenue.',
    deliveryAddress: `Adresse de l'intervention`,
    empty_list: 'Cette liste est vide.',
    first_name: 'Prénom',
    invoice_address: 'Adresse de facturation',
    last_name: 'Nom',
    per_page: 'Par page',
    product: 'Produit',
    reset: 'Réinitialiser',
    search: 'Rechercher',
    status: 'Statut',
  },
  fourOfour: {
    page_name: `Désolé cette page n'existe pas`,
    title: `oh non ! ERREUR 404<br/>la page demandée a disparu`,
    desc: `Un des tuyaux du serveur est bouché ou alors nous avons un court-jus !<br/>Un expert est déjà au travail pour rétablir tout ça :)<br/>En attendant nous vous invitons à poursuivre la navigation sur notre site.`,
    cta_back_home: `Retour à l'accueil`,
  },
  domains: {
    O: `plomberie`,
    E: `électricité`,
    G: `gaz`,
    C: `climatisation`,
    H: `chauffage`,
    S: 'serrurerie',
    LM: `electromenager`,
    slugs: {
      O: `plomberie`,
      E: `electricite`,
      G: `gaz`,
      C: `climatisation`,
      H: `chauffage`,
      S: 'serrurerie',
      LM: `electromenager`,
    },
  },
  authFailed: {
    title: "Un problème technique nous empêche d'afficher la page",
  },
  navbar: {
    profil: 'Profil',
    order_tracking_button: `Suivre votre commande`,
    homeserve_link: 'HomeServe.fr',
    who_we_are_link: `Qui sommes-nous ?`,
    help_link: `Aide`,
    pro_link: `Espace Pro`,
    phone_number: '{phoneNumber}',
    phone_number_link: `tel:${formatPhone(PARTNER_CONFIG.supportPhone, '+33')}`,
    phone_fees: `Service gratuit + coût de l'appel`,
    logout: `déconnexion`,
    history: `commandes`,
  },
  SEOZone: {
    diagnosis: 'diagostic',
    repair: 'Dépannage',
  },
  footer: {
    hotline_title: `Vous êtes un <strong>particulier</strong>`,
    hotline_disclaimer_week: `du {start_day} au {end_day} de {start_hour} à {end_hour}`,
    hotline_disclaimer_week_and_week_end: `du {start_week_day} au {end_week_day} de {start_week_hour} à {end_week_hour} et du {start_week_end_day} au {end_week_end_day} de {start_week_end_hour} à {end_week_end_hour}`,
    service_name: `HomeServe Dépannage`,
    esca_certification: `Le groupe HomeServe a été élu service client de l'année.<sup>(1)</sup>&nbspCatégorie Services à l’habitat - Étude BVA Group - Viséo CI - mai à juillet 2018 - Plus d’infos sur <a href="http://www.escda.fr" target="_blank" rel="noopener">escda.fr</a>.`,
    who_we_are_link: `Qui sommes nous ?`,
    join_network_link: `Rejoindre notre réseau`,
    pro_app_download: `<strong>Professionnels ?</strong> Téléchargez votre application mobile.`,
    app_link_ios: `https://itunes.apple.com/us/app/homeserve-dépannage-pro/id1449566378`,
    app_link_android: `https://play.google.com/store/apps/details?id=fr.homeserve.depannage.traderapp`,
    onboarding: {
      title: `Vous êtes un <strong>professionnel</strong>`,
      join_us: `Rejoindre notre réseau`,
    },
    links: {
      plumbing: `Dépannage plomberie`,
      electricity: `Dépannage électricité`,
      heating: `Dépannage chauffage`,
      air_conditionning: `Dépannage climatisation`,
      gas: `Dépannage gaz`,
      help: `Besoin d'aide`,
      near_home: `Nos professionnels près de chez vous`,
      who_we_are: `Qui sommes-nous ?`,
      terms_and_conditions: `Conditions générales de vente`,
      privacy: `Politique de données personnelles`,
      legal_notice: `Mentions légales`,
      our_rates: `Nos tarifs`,
      legal_mentions: 'Mentions légales',
      conditions_of_use: "Conditions générales d'utilisation",
      sales_conditions: 'Conditions générales de vente',
      privacy_policies: 'Politique de données personnelles',
      cookies_policies: 'Politique des cookies',
      cookies_management: 'Gestion des cookies',
    },
    asterisks: {
      homeserve_data: `(1) Données HomeServe basées sur les interventions déployées entre avril 2018 et mars 2019.`,
    },
  },
  legal_notice: {
    title: 'Mentions légales',
    download_pdf: 'Télécharger le pdf',
    content: `
    <p>
      Le présent site internet
      <a href="${PARTNER_CONFIG.websiteUrl}">${PARTNER_CONFIG.websiteUrl}</a> est édité et exploité par la
      société HOMESERVE ON DEMAND, société par actions simplifiée à associé unique, au
      capital social de 100 000 euros, immatriculée au Registre du Commerce et des Sociétés
      de Lyon sous le numéro 838 756 237, dont le siège social est sis 9 rue Anna Marly
      69007 LYON, et dont le numéro de TVA intracommunautaire est le FR 65 838756237.
    </p>
    <p>
      L’ensemble des règles encadrant l’utilisation du site, et de ses différents contenus est
      détaillé dans les
      <router-link :to="{ name: 'termsAndConditions' }">Conditions Générales d’Utilisation</router-link>&nbsp;(CGU).
    </p>
    <p>
      Vous pouvez nous joindre par téléphone au ${formatPhone(
        PARTNER_CONFIG.supportPhone,
        '0',
        true,
      )} ou par courrier électronique
      à l’adresse
      <a
        href="mailto:${PARTNER_CONFIG.supportEmail}"
      >${PARTNER_CONFIG.supportEmail}</a>.
    </p>
    <p>Le directeur de la publication est Mme Delphine Bittoun.</p>
    <p>
      Ce site est hébergé par la société Google France, située au 8 rue de Londres, 75 009,
      Paris ; Tél : 01 42 68 53 00
    </p>`,
  },
  our_rates: {
    title: `Nos tarifs en dépannage plomberie, électricité, gaz et serrurerie`,
    desc: `Notre service HomeServe à la demande s’engage à être transparent sur <strong>le prix des dépannages</strong> effectués par notre réseau de professionnels certifiés. Il est important pour nous de faire apparaître clairement le prix de nos interventions. Ainsi vous évitez les mauvaises surprises grâce à nos prix forfaitaires garantis !<br/>
    Le <strong>coût le plus juste</strong> pour un <strong>dépannage d’urgence à votre domicile</strong> est défini suivant différents critères. Par exemple, le tarif d’un dépannage plomberie est différent de celui d’un dépannage à domicile par un électricien. L’expertise, le temps d’intervention et les fournitures ne sont pas les mêmes !`,
    domain_card: {
      title: `Dépannage d'urgence {domain}`,
      button: 'Tarifs {domain}',
    },
    no_data: `Pas de données`,
    packages_title: `Dépannage à domicile : nos 3 forfaits selon le créneau horaire`,
    package_slot_normal_rate: {
      title: '1er forfait tarifaire',
      content: `Si vous choisissez une intervention de 8h à 19h en semaine, vous devez vous référez à la colonne « prix de base » pour connaître le tarif. Il s’agit d’un prix sans supplément.`,
    },
    package_slot_evening_saturday: {
      title: '2ème forfait tarifaire',
      content: `Pour un dépannage d’urgence en soirée après 19h, ou le samedi, vous devez vous référer à la colonne « prix soir & samedi » pour connaître le prix. `,
    },
    package_slot_sunday: {
      title: '3ème forfait tarifaire',
      content: `Lors d’un dépannage le dimanche et les jours fériés, le tarif de l’intervention d’un plombier sera indiqué dans la colonne « prix dimanche & jours fériés ».`,
    },
    price_information: {
      title: 'Quels tarifs pour un dépannage à domicile d’urgence ?',
      content: `Découvrez nos tarifs de dépannage d’urgence dans le domaine qui vous intéresse. Le prix de chaque prestation est fixé selon l’horaire, la durée d’intervention et les fournitures nécessaires.`,
    },
    table_headers: {
      job: `Prestation`,
      base_price: `Prix de base`,
      evening_saturday_price: `Prix soir & samedi`,
      sunday_price: `Prix dimanche & jours fériés`,
      duration: `Durée`,
      spare_parts: `Fournitures`,
    },
  },
  our_rates_by_domain: {
    domain_card: {
      button: `Je suis dépanné`,
    },
    electricite: {
      title: `Quel est le prix d’un dépannage électrique ?`,
      desc: `<p>Retrouvez ci-dessous l’ensemble de <strong>nos tarifs pour un dépannage électrique</strong> d’urgence.
      Nous affichons nos prix en toute transparence afin de vous éviter toutes mauvaises
      surprises !</p>
      <p>L’intervention d’un <strong>professionnel certifié</strong> à votre domicile est nécessaire dans certaines
      situations. Une panne électrique, un disjoncteur qui saute… l’électricien vous assure une
      prestation de qualité. Ainsi vous écartez également tous les dangers relatifs à une réparation
      électrique.</p>
      <p>Un dépannage d’urgence à domicile est facturé sur la base de plusieurs critères : les frais de
      déplacement, les fournitures, l’heure de l’intervention etc.
      Avec HomeServe Dépannage vous disposez avant chaque déplacement d’<strong>un devis détaillé</strong>
      qui vous permet mieux comprendre le contenu de l’intervention.</p>`,
      price_title: `Découvrez les tarifs de nos prestations de dépannage en électricité`,
      price_description: `Nous vous proposons plusieurs forfaits : le prix de base, le prix soir &amp; samedi, le prix
      dimanche &amp; jours fériés.
      La durée de l’intervention ainsi que le prix des fournitures sont indiqués pour chaque
      problème électrique.`,
    },
    plomberie: {
      title: `Quel est le tarif d’un dépannage plomberie ?`,
      desc: `<p>Les services d’un plombier sont multiples. Retrouvez ci-dessous l’ensemble de nos
      dépannages en plomberie et leurs prix. Nous affichons nos tarifs en toute
      transparence afin de vous éviter des coûts imprévus.</p>
      <p>L’intervention d’un <strong>plombier professionnel</strong> peut s’effectuer <strong>à partir de 99 euros</strong>.
      Ce prix comprend le <strong>déplacement, la main d’œuvre et les fournitures</strong>.</p>
      <p>Le tarif pour l’intervention d’un plombier lors d’une urgence à domicile varie selon
      l’horaire. Pour plus de clarté, nous vous proposons 3 forfaits selon le créneau
      demandé.</p>`,
      price_title: `Découvrez ci-dessous les prix de nos prestations de dépannage en
      plomberie`,
    },
    gaz: {
      title: `Quel est le prix d’un dépannage pour une fuite de gaz ?`,
      desc: `<p>Le <strong>prix pour un dépannage lors d’une fuite de gaz</strong> est de 129 euros. Ce dernier peut
      varier selon le jour de l’intervention, semaine, week-end ou jour férié. HomeServe
      Dépannage s’engage à être transparent au niveau du prix de l’intervention d’un
      professionnel certifié.</p>
      <p>Les problèmes de fuite de gaz nécessitent une attention particulière et une technique
      exigeante. Une mauvaise manipulation peut avoir des conséquences graves, et
      compromettre l’intégrité de la tuyauterie. Le changement d’un flexible de gaz ou la détection
      d’une fuite de gaz doit faire l’objet d’une <strong>intervention sérieuse</strong> dont les tarifs sont visibles
      ci-dessous.</p>`,
      price_title: `Découvrez les tarifs de nos prestations de dépannage gaz`,
      price_description: `Chaque prestation répond à un besoin de dépannage d’urgence. Le prix est fixé selon le
      jour, la durée de l’intervention et les fournitures nécessaires. Ainsi vous évitez toutes
      mauvaises surprises lors de la facturation !`,
    },
    serrurerie: {
      title: `Quel est le tarif d’un dépannage d’urgence en serrurerie ?`,
      desc: `Le <strong>tarif d’un serrurier</strong> agréé HomeServe peut varier selon s’il intervient en journée,
      le week-end ou un jour férié. Celui-ci peut par exemple aller de 213 à 369 euros pour
      une porte verrouillée.
      Avec HomeServe Dépannage vous avez la certitude de faire appel à un <strong>serrurier
      professionnel</strong>. Avant chaque intervention, vous recevez un devis détaillé.`,
      price_title: `Découvrez les tarifs de nos prestations de serrurerie`,
      price_description: `Selon votre problème, retrouvez nos tarifs répartis par forfait : prix de base, soir &amp;
      samedi, dimanche et jours fériés. Nos interventions comprennent <strong>la main d’œuvre,
      les frais de déplacement ainsi que les fournitures</strong> indispensables pour ouvrir
      votre porte. Si votre porte est claquée mais non verrouillée, le prix sera inférieur.`,
    },
    electromenager: {
      title: `Combien coûte une réparation d’appareil électroménager ?`,
      desc: `Il vous est certainement déjà arrivé de faire appel à un plombier pour réparer une canalisation bouchée ou à un électricien pour un tableau électrique défectueux. 
      Pourquoi ne pas faire de même pour une panne d’électroménager ?<br />
      Plutôt que de jeter vos appareils électroménagers en panne pour les remplacer par des neufs, 
      HomeServe vous propose de les réparer pour prolonger leur durée de vie et faire des économies !<br />
      Retrouvez ci-dessous l’ensemble de nos tarifs pour le dépannage d’un appareil électroménager. En fonction de votre problématique, nous vous proposons 3 niveaux de réparation.`,
      price_title: `Découvrez ci-dessous l’ensemble de nos prestations de dépannage en électroménager`,
      price_description: `Liste de toutes les prestations électroménager On Demand`,
    },
  },
  diagnosis: {
    page_name: 'Page diagnostic',
    title: `<strong>Besoin d'un dépannage</strong>&nbsp;en plomberie, électricité ou gaz&nbsp;?`,
    domain_choice: `Quel est votre besoin ?`,
    error_domain_choice: `Veuillez renseigner votre problème`,
    postcode: `Votre code postal`,
    postcode_hint: `Afin de nous assurer que nous avons bien un expert près de chez vous`,
    postcode_question: `Votre code postal ?`,
    error_invalid_postcode: `Veuillez renseigner un code postal valide`,
    error_not_covered_postcode: `Désolé, nous n'intervenons pas dans votre commune`,
    problem_question: `Quel est votre problème ?`,
    advantage_1: `Devis gratuit en 2 minutes\xa0!`,
    advantage_2: `Prix fixes et garantis`,
    advantage_3: `Annulation sans frais\xa0*`,
    disclaimer: `* Annulation sans frais jusqu'à 24 heures avant l'arrivée du pro`,
    gaz: {
      bottom_title: 'Demande de devis dépannage gaz',
      bottom_content: `<p>Un problème de canalisation de gaz ? Vous cherchez un dépannage gaz pas cher ?
      Obtenez en quelques clics votre devis gratuit pour être dépanné par l’un de nos
      professionnels du gaz en urgence.</p>
      <p>Retrouvez nos <a href="/nos-tarifs/depannage-gaz">tarifs pour nos interventions de dépannage gaz</a> directement sur notre site.
      Pas de mauvaises surprises lors de la réalisation du devis nos prix sont forfaitaires.</p>`,
    },
    electricite: {
      bottom_title: 'Demande de devis pour un dépannage électrique',
      bottom_content: `<p>Besoin d&#39;un devis en électricité ? Obtenez en quelques clics votre devis électrique gratuit
      pour un dépannage en urgence.</p>
      <p>Nous vous garantissons un prix fixe ! L’ensemble de <a href="/nos-tarifs/depannage-electricite">nos tarifs pour le dépannage électrique</a>
      sont disponibles directement sur notre site. Que ce soit pour le <a href="/produit/remplacement-interrupteur">remplacement d’un
      interrupteur</a> ou encore le changement d’une prise électrique, vous n’aurez pas de mauvaise
      surprise !</p>`,
    },
    plomberie: {
      bottom_title: 'Demande de devis pour un dépannage plomberie',
      bottom_content: `<p>Vous avez besoin d&#39;un devis plomberie ? Obtenez rapidement votre devis gratuit pour un
      dépannage en urgence.</p>
      <p>Quel que soit votre problème : <a href="/produit/debouchage-des-canalisations-camion-hydrocureur">débouchage des canalisations</a>, recherche de fuite d’eau, fuite
      des toilettes ou encore remplacement de robinets, nos tarifs sont sans surprise.</p>
      <p>Vous pouvez retrouver l’ensemble de nos <a href="/nos-tarifs/depannage-plomberie">tarifs plomberie</a> directement sur notre site.</p>`,
    },
    serrurerie: {
      bottom_title: 'Demande de devis dépannage en serrurerie',
      bottom_content: `<p>Vous avez besoin d&#39;un devis serrurerie ? Vous recherchez un dépannage serrurerie pas cher
      et garanti ?</p>
      <p>Obtenez rapidement un devis gratuit et garanti sans surprise ! Quels que soient les
      problèmes : <a href="/produit/ouverture-porte-verrouillee">une serrure qui bloque</a>, tourne dans le vide ou encore une porte qui ne ferme
      plus, nos prix sont forfaitaires et connus d’avance ! Vous pouvez retrouver ici l’ensemble de
      nos <a href="/nos-tarifs/depannage-serrurerie">tarifs dépannage serrurerie</a>.</p>`,
    },
    electromenager: {
      bottom_title: 'Devis pour la réparation d’un appareil électroménager',
      bottom_content: `<p>L’avantage en choisissant HomeServe Dépannage est que vous pouvez obtenir un devis gratuit en seulement 2 minutes sur notre site !
      Ainsi, vous saurez rapidement combien vous coûtera la réparation de votre appareil électroménager.</p>
      <p>Une fois le devis validé, vous serez rapidement contacté par l’un de nos professionnels pour convenir ensemble d’un rendez-vous à votre domicile afin de dépanner votre appareil.</p>`,
    },
    submit: `Être dépanné`,
    previous_step_button: `étape précédente`,
    not_sure_problem: `Vous n'êtes pas sûr du problème ?`,
    not_sure_problem_text: `Nous vous aidons à diagnostiquer votre problème :`,
    not_sure_problem_contact: `Contactez-nous au`,
    diagnosis_next_step: `Une fois votre commande passée, notre professionnel vous contacte avant son passage pour <strong>affiner le diagnostic.</strong>`,
    google_tag_manager_category_domain: 'Diagnostic {domain}',
    google_tag_manager_insider_action: 'Initié',
    google_tag_manager_uncovered_action: 'Non couvert',
    google_tag_manager_no_trader_action: 'Aucun trader disponible',
    google_tag_manager_select_problem_action: 'Choix problème',
  },
  reinsurance: {
    number_one: `Numéro 1 en France`,
    no_surprise: `Devis détaillé sans suprise`,
    customer_service: `Service client à votre écoute`,
    traders_network: `1000 artisans à votre service`,
    customer_satisfaction: `96% de taux de satisfaction`,
  },
  breadcrumb: {
    to_home: `Accueil du site`,
  },
  homepage: {
    repair: 'Dépannage',
    page_name: `Page d'accueil`,
    homeserve_block: {
      block_simple: `Simple, rapide, serein`,
      block_simple_desc: `En quelques clics, estimez le coût de votre intervention, un professionnel vous rappellera suite à votre commande et affinera avec vous les détails de l'intervention.`,
      block_pro: `Pro, experts, fiables`,
      block_pro_desc: `Les professionnels HomeServe Dépannage sont triés et sélectionnés pour leurs compétences et leurs expertises, nous répondons de leur savoir-faire.`,
    },
    homeserve_expert: {
      title: `Simplifiez vos dépannages et petits travaux`,
      block_surprise: {
        title: `Sans surprise`,
        desc: `Devis détaillé gratuit et prix communiqué à l'avance`,
      },
      block_trust: {
        title: 'En toute confiance',
        desc: 'Des prestataires experts et agréés',
      },
      block_proximity: {
        title: 'Proche de chez vous',
        desc: '4000 professionnels HomeServe en France',
      },
      block_quick: {
        title: 'Rapidement',
        desc: 'Prise de rendez-vous confirmée dans les plus brefs délais',
      },
    },
    products_lists: {
      odd_jobs: {
        description:
          'Pour vos petits travaux et réparations de tous les jours, choisissez la prestation qui correspond à votre besoin. Nous vous proposons un devis détaillé, en toute transparence, et sélectionnons pour vous un professionnel de notre réseau, proche de chez-vous.',
      },
      top_repairs: {
        description:
          'Obtenez votre devis gratuit en 2 minutes. Un professionnel de notre réseau vous contactera dans les plus brefs délais pour confirmer avec vous le RDV.',
      },
    },
  },
  product_page: {
    discount_code: 'Code promo',
    page_name: 'Page produits',
    included_in_job: `Compris dans le prix :`,
    excluded_in_job: `Non compris :`,
    excluded_in_job_details: `Pour réaliser des travaux non inclus dans cette prestation, contactez-nous directement ou notre prestataire afin d'obtenir un devis complémentaire.`,
    advices: `Nos conseils en dépannage {domain}`,
    linked_jobs_title: `Nos clients ont aussi consulté`,
    quotation_title: `Votre devis`,
    order_my_repair: 'Demander une intervention',
    order_my_repair_sticky: 'Je commande',
    slot: `Créneau :`,
    vat_included: `ttc`,
    spare_parts_included: `Pièces et fournitures`,
    callout_included: `Frais de déplacement`,
    workforce_included: `Jusqu'à {duration} de main d'œuvre`,
    additionnal_fees: `Supplément horaire`,
    additionnal_fees_nights_and_sundays: 'Majoration soir et week end',
    moreDetails: 'En savoir plus',
    title: 'Faites appel à notre réseau de Pros pour réaliser votre intervention en toute confiance !',
    title_seo: 'Pourquoi faire intervenir nos Pros ?',
    title_order: `Comment se passe l'intervention en {domain}`,
  },
  job_schedule: {
    optional: `Facultatif`,
    title: `Aidez notre Pro en précisant votre demande`,
    schedule_comment_label: `Décrivez l'endroit de l'intervention, est-il facile d'accès ? (par exemple "Fuite dans un placard / sous l'évier de la cuisine / coule beaucoup")`,
    schedule_comment_placeholder: `Tout ce qui pourrait être utile`,
    attach_photo: `Ajoutez une photo pour le professionnel afin de préciser votre besoin : ex. photo de votre fuite d’eau, du tableau électrique, etc.`,
    attach_photo_responsibility_warning: `Vous êtes responsables des contenus que vous téléchargez,  veuillez notamment vous assurer de ne pas transmettre de photos de personnes reconnaissables.`,
    attach_photo_choose_file: `Choisissez un fichier ou cliquez-déposez le ici`,
    place_order_cta: `Je valide`,
    slot: `Créneau`,
    choose_job_slot: `Veuillez choisir un créneau d'intervention`,
    breadcrumb_item: `Prise de rendez-vous`,
    max_files_error: `Vous pouvez selectionner au maximum {maxFile} fichiers`,
    name_files_error: `Vous avez déjà un fichier nommé {fileName}`,
  },
  personnal_information: {
    address_hint: `Veuillez cliquer sur une des adresses suggérées`,
    step_one: `1 - Mes informations`,
    step_two: `2 - Ma commande`,
    step_three: `3 - Ma confirmation`,
    step_end: `2 - Fin`,
    information: {
      title: `Renseignez vos informations personnelles`,
      job_address: `Adresse de l'intervention :`,
      invoice_address_different: `Cochez cette case si vous avez une adresse de facturation différente`,
      invoice_address_different_link: `Si votre adresse de facturation est différente de votre adresse d'intervention <span>cliquez-ici</span>`,
      invoice_address: `Adresse de facturation :`,
      email_label: `Votre adresse email`,
      email_hint: `Afin de vous envoyer les informations relative à votre dépannage`,
      email_invalid: `L'email n'est pas valide`,
      email_already_taken: `Cet email est déjà utilisé`,
      field_min_length: `XXXLe champ xxx requiert xxx caractères minimum`,
      field_required: `XXXLe champ xxx est obligatoire`,
      housing: {
        field_name: 'Âge du logement',
        label: `En validant, j'atteste que mon logement, achevé depuis plus de 2 ans, est utilisé à des fins d'habitation. Si ce n'est pas le cas, je coche la case `,
        more_detail: `(en savoir +)`,
        tooltip: `Le taux de TVA applicable est de 20 % pour les logements de moins de 2 ans ou utilisés à des fins professionnelles.`,
      },
      optin: {
        email: {
          on_demand: `J'accepte de recevoir des informations et offres par email de la part de HomeServe On Demand`,
          group: `Vous acceptez de recevoir par email nos conseils et offres spéciales ainsi que celles du Groupe HomeServe`,
          group_tooltip: `En tant que spécialiste des services pour la maison, HomeServe vous propose de recevoir ponctuellement des offres pour des solutions d'assistance et/ou d'assurance (par HomeServe), des offres de dépannage, de réparation et de travaux de rénovation (par HomeServe OnDemand), ainsi que des solutions de chauffage et de climatisation (notamment d'entretien par des sociétés filiales de HomeServe Energy Services).`,
        },
        phone_snailmail: {
          group: `Si vous ne souhaitez pas recevoir d'offres commerciales par courrier ou par téléphone de notre part et du groupe HomeServe`,
          group_click_here: 'cliquez ici',
          group_opt_out: `Je ne souhaite pas recevoir d'offre par courrier ou par téléphone de la part de : HomeServe OnDemand (offres de dépannage, réparation et travaux de rénovation), HomeServe (contrats d'assistance et/ou d'assurance pour l'habitat), HomeServe Energy Services et ses filiales (notamment contrats d'entretien chauffage et climatisation)`,
          group_opt_out_confirm: `Ne pas profiter de vos offres`,
          group_opt_out_cancel: `Profiter de vos offres`,
        },
      },
      cta_validate_information: `Valider mes informations`,
      form_error: `Une erreur est survenue, veuillez vérifier les informations que vous avez renseignées.`,
    },
    contact: {
      title: `Civilité`,
      title_choices: {
        mr: `M.`,
        mrs: `Mme`,
      },
      title_placeholder: `Choisissez`,
      first_name: `Prénom`,
      last_name: `Nom`,
      address1: `Adresse de l’intervention`,
      address2: `Complément d'adresse`,
      address2_placeholder: `digicode, interphone, bâtiment`,
      edit_location_1: `Modifier les `,
      edit_location_2: `informations de localisation`,
      phone_placeholder: `Téléphone (pour que le prestataire puisse confirmer le diagnostic et le RDV)`,
      phone: `Téléphone`,
      phone_mandatory: `Le téléphone est obligatoire`,
      post_code: `Code postal`,
      country: `Pays`,
    },
  },
  order_confirmation: {
    please_fill_your_payment_informations: `Veuillez saisir vos informations de paiement ci-dessous.`,
    you_will_only_be_charged_after_the_execution: `Vous ne serez débité qu'après l'exécution de votre intervention.`,
    title: `Nous intervenons d’abord, vous payez ensuite`,
    total_price: `Total à payer à la fin de l'intervention :`,
    renounce_retractation: `Lors de la prise de rendez-vous avec notre Pro, en cas d'intervention prévue avant la fin du délai légal de rétractation de 14 jours, je reconnais renoncer à mon droit de rétractation.`,
    accept_terms: `Je reconnais avoir pris connaissance des <a href="{linkTerms}">FIXLINKconditions générales de vente</a> en ligne et les <a href="{linkSpecificConditions}">FIXLINKconditions particulières de l'intervention</a>.`,
    checkbox_missing: `Veuillez cocher toutes les cases obligatoires`,
    payment_option_website: `Empreinte CB et validation sur place`,
    payment_option_onsite: `Paiement sur place après l'intervention`,
    select_payment: `Sélectionnez votre mode de paiement :`,
    select_payment_error: `Veuillez sélectionner un mode de paiement`,
    card_invalid: `Vos données de carte bancaire ne sont pas valides, veuillez vérifier les informations renseignées`,
    place_order: `Terminer ma commande`,
    payment_error: `Une erreur est survenue, merci de réessayer plus tard`,
    confirmation_email: `Nous vous enverrons une confirmation de commande instantanée à l'adresse <strong>{email}</strong>`,
    confirmation_sms: `Vous recevrez un SMS avec les coordonnées du professionnel qui vous rappellera pour confirmer avec vous le créneau d'intervention`,
    intervention_date_confirm: `Confirmation de la date d'intervetion`,
    cgu_confirm: `Conditions générales de vente`,
  },
  payment_form: {
    card_number: `Numéro de la carte`,
    expiry_month: `Mois`,
    expiry_year: `Année`,
    security_code: `Cryptogramme de sécurité (3 chiffres au dos de votre carte)`,
  },
  order_success: {
    title: `Merci, votre commande est confirmée`,
    confirmation_email: `Vous allez recevoir un email de confirmation avec le récapitulatif de votre commande`,
    next_steps: `Voici les prochaines étapes`,
    step_1: `Vous allez recevoir un <strong>SMS</strong>&nbsp;avec les coordonnées du professionnel`,
    step_2: `Le professionnel appelle dans les plus brefs délais pour confirmer l'intervention et le diagnostique`,
    step_3: `Le professionnel intervient, si le diagnostique n'est pas bon un contre diagnostique est fait que vous pouvez refuser <strong>sans frais</strong>`,
    step_4: `Vous payez en ligne après l'intervention.`,
    order_tracking: `Vous pouvez retrouver le détail de votre commande et le suivi de votre intervention en cliquant ci-dessous:`,
    cta_track_order: `Suivre mon intervention`,
  },
  tracking: {
    title: `Suivi d'intervention`,
    intro: `Bonjour {title} {lastname}, voici votre suivi d'intervention`,
    login: {
      title: `Connectez-vous à votre espace de suivi de commande`,
      file_reference: `numéro de dossier`,
      last_name: `nom de famille`,
      cta_login: `Accéder à mon suivi`,
      login_invalid_error: `Nous ne reconnaissons pas ce dossier. Retrouvez votre numéro de dossier facilement dans votre email de confirmation de commande.`,
      login_connection_error: `Impossible de se connecter pour le moment. Veuillez réessayer plus tard.`,
    },
    contact: {
      title: `Des questions ?`,
      faq_link: `Consultez notre FAQ`,
      contact_us_phone: `ou contactez-nous au :`,
      contact_fees_phone: `(service gratuit + coût de l'appel)`,
      contact_email: `ou par email à :`,
      contact_email_address: `${PARTNER_CONFIG.supportEmail}`,
    },
    product_display: {
      tracking_number: `Numéro de dossier`,
      job_address: `Addresse d'intervention`,
      job_date: `Date : `,
      total_cost: `Prix total : `,
    },
    job_done: {
      title: `Intervention terminée`,
      desc: `Votre intervention est maintenant terminée.`,
    },
    job_in_progress: {
      title: `Intervention en cours`,
      desc: `Votre intervention est en cours par notre artisan.`,
    },
    job_planned: {
      title: `Votre intervention est planifiée`,
      trader: `Votre artisan`,
      contact: `Informations de contact :`,
      email: 'Email',
      phone: `Téléphone`,
      planned_date: `Date d'intervention prévue`,
    },
    looking_trader: {
      title: `Nous recherchons un artisan`,
      desc: `Nous recherchons l'artisan le mieux qualifié pour répondre à votre besoin dans votre secteur`,
    },
    trader_found: {
      title: `Un artisan a été trouvé`,
      desc: `Notre artisan va prendre contact avec vous afin de confirmer les modalités de l'intervention`,
    },
    new_quote: {
      title: `Devis actualisé`,
      desc: `Votre artisan a validé le créneau horaire`,
      desc_cheaper: `Votre artisan a modifié le créneau horaire, entraînant une baisse du montant du devis`,
      cta_download_quote: `Télécharger mon devis`,
    },
    quote_updated: {
      title: `Devis à jourXXXX`,
      desc: ``,
    },
    new_quote_accepted: {
      title: `Nouveau devis accepté`,
      desc: `Votre acceptation a été traitée avec succès.`,
    },
    new_quote_refused: {
      title: `Nouveau devis refusé`,
      desc: `Vous avez refusé le nouveau devis.`,
    },
    no_trader: {
      title: `Nous n'avons pas pu trouver d'artisan pour votre intervention`,
      desc: `Nous sommes désolés, malgré tous nos efforts nous n'avons pas trouvé d'artisan disponible à proximité de chez vous. Vous ne serez pas débité.`,
    },
    order_canceled: {
      title: `Votre commande est annulée`,
      desc: `Votre intervention a été annulée sans frais.`,
    },
    order_processing_payment: {
      title: `Paiement de la commande`,
      desc: `Suite à la clôture de l'intervention vous allez être débité du montant de {price} TTC`,
    },
    order_done_paid: {
      title: `Votre intervention est terminée`,
      desc: `Votre intervention est maintenant terminée. Vous pouvez afficher votre facture en cliquant sur le bouton ci-dessous.`,
      cta_display_invoice: `Télécharger ma facture`,
    },
    trader_start_moving: {
      title: `Votre artisan est en route`,
      desc: `Votre artisan est en route pour se rendre sur le lieu de l'intervention.`,
    },
    new_order: {
      title: `Commande acceptée`,
      cancel_btn: `Annuler ma commande`,
      cancel_confirmation: `Souhaitez-vous vraiment annuler votre commande ?`,
      cancel_reason: `Veillez préciser la raison de l'annulation`,
      cancel_reason_diy: `J'ai trouvé une autre solution (réparé tout seul, autre prestataire, problème résolu,...)`,
      cancel_reason_trader: `Le prestataire ne s'est pas présenté à l'heure convenue`,
      cancel_reason_other: `Autre motif (veuillez préciser)`,
      cancel_reason_other_label: `Merci de nous indiquer le motif d'annulation de la commande`,
      cancel_confirm: `Annuler ma commande`,
      cancel_cancel: `Retour`,
      display_quotation: `Afficher le devis`,
    },
    review_trader: {
      title: `Evaluez votre artisan`,
      desc: `Votre intervention est maintenant terminée et nous espérons qu'elle vous a apporté pleine satisfaction. Nous vous invitons à prendre une poignée de secondes pour répondre à quelques questions. Cela nous aide à vous offrir une expérience de qualité. Merci !`,
      cta_rate_trader: `Répondre à quelques questions`,
    },
    trader_has_canceled: {
      title: `Votre artisan a annulé l'intervention`,
      desc: `Un autre artisan va vous contacter pour convenir d'un nouveau rendez-vous.`,
      desc_no_show: `Vous n'étiez pas présent à l'heure convenue du rendez-vous.`,
    },
    order_canceled_fees: {
      title: `Paiement des frais d'annulation`,
      desc: `Votre intervention a été annulée, en accord avec nos CGV, nous allons procéder au prélèvement d'un montant forfaitaire pour couvrir le déplacement de notre prestataire.`,
      cta_display_invoice: `Télécharger ma facture`,
    },
  },
  review: {
    already_submited: `Le formulaire à déjà été soumis pour cette intervention`,
    validate_my_opinion: `Valider mon avis`,
    continuous_improvment: `Afin de continuer à améliorer notre service et notre réseau de professionnel, nous aimerions connaître votre avis sur le professionnel qui est intervenu chez vous.`,
    title: `Evaluez le professionnel qui est intervenu chez vous !`,
    experience_feedback: `Chez HomeServe, nous attachons une grande importance à votre retour d'expérience`,
    thank_you_for_your_review: `Merci pour votre avis, il nous permet d'améliorer nos services.`,
  },
  who_are_we_page: {
    page_name: 'Page qui sommes-nous ?',
  },
  fix_appointment_page: {
    page_name: 'Page prise de rendez-vous',
  },
  tunnel_page: {
    page_name: 'Page tunnel de commande',
    info_section: {
      page_name: 'Page tunnel de commande - Informations',
      google_tag_manager_category: 'Tunnel informations',
      google_tag_manager_error_action: 'Erreur',
      google_tag_manager_error_phone: 'Téléphone',
    },
    summary_section: {
      page_name: 'Page tunnel de commande - Récapitulatif',
    },
    confirmation_section: {
      page_name: 'Page tunnel de commande - Confirmation',
    },
    end_section: {
      page_name: 'Tunnel - Finalisation Call Center',
    },
  },
  follow_order_login_page: {
    page_name: 'Page de connexion suivi de commande',
  },
  conditions_of_use_page: {
    page_name: "Conditions générales d'utilisation",
  },
  terms_and_conditions_page: {
    page_name: 'Conditions générales de vente',
  },
  cookies_policy: {
    page_name: 'Politique des cookies',
  },
  legal_notices_page: {
    page_name: 'Mentions légales',
  },
  faq_page: {
    page_name: 'FAQ',
  },
  our_rates_page: {
    page_name: 'Nos tarifs',
  },
  privacy_policy: {
    page_name: 'Politique de données personnelles',
  },
  review_trader: {
    page_name: 'Evaluer mon professionnel',
  },
  logout: {
    page_name: 'Déconnexion',
  },
  login: {
    page_name: 'Page de login',
  },
  history_page: {
    follow_order: 'Suivre la commande',
    post_code: `Code postal de l'intervention`,
    title: 'Vos commandes',
  },
  profil_page: {
    details: 'Détails',
    bank_account_identity: 'RIB HomeServe On Demand',
    download_bank_account_identity: 'Télécharger le RIB au format PDF',
    title: 'Mon profil',
  },
  order_status: {
    new: 'nouvelle',
    in_progress: 'en cours',
    incomplete_need_terms: 'attente cgu/cgv',
    incomplete_waiting_confirm: 'attente de confirmation',
    new_quote: 'nouveau devis',
    new_quote_action_needed: 'nouveau devis - action requise',
    canceled: 'annulée',
    canceled_fees: 'annulée avec frais',
    canceled_fees_waiting_payment: 'annulée en attente de paiement',
    canceled_fees_paid: 'annulée payée',
    done_paid: 'terminée payée',
    done_unpaid: 'terminée non payée',
    done_payment_failed: 'terminée - paiement en échec',
    done_waiting_payment: 'terminée en attente de paiement',
  },

  // useful for GTM
  legal_domain: 'Juridique',
  transversal_domain: 'Transverse',

  your_preferences_will_be_communicated_to_the_convenience_store_who_will_call_you_back_to_schedule_the_appointment:
    'Vos préférences seront communiquées au dépanneur qui vous rappelera afin de fixer le rendez-vous',
  select_your_appointment_preferences: 'Séléctionner vos préférences de rendez-vous',
  follow_order: {
    page_name: 'Suivi - {step}',
    google_tag_manager_domain: 'Suivi de commande',
    cant_connect_please_check_your_informations: 'Impossible de se connecter, veuillez vérifier vos informations.',
    cant_connect_please_try_again_later: 'Impossible de se connecter, veuillez réessayer ultérieurement',
    looking_trader_title: 'Nous recherchons un prestataire',
    looking_trader_text: 'Nous recherchons un prestataire correspondant à votre problème proche de chez vous',
    order_placed: 'Commande passée',
    order_reference: 'N° de commande',
    deployment_reference: 'N° intervention',
    deployment_address: "Addresse d'intervention",
    included_spare_parts: 'Pièces et fournitures',
    callout_price: 'Frais de déplacement',
    job_duration: "Jusqu'à {duration} de main d'oeuvre",
    estimate_total: 'Total du devis',
    display_estimate: 'Afficher le devis',
    cancel_my_order: 'annuler ma commande',
    accept_the_quotation: 'accepter le devis',
    refuse_the_quotation: 'refuser le devis',
    display_updated_quotation: 'Afficher le devis mis à jour',
    any_questions: 'Des questions?',
    consult_our_faq: 'consultez notre FAQ',
    or_contact_us_at: 'ou contactez nous au',
    free_service_plus_cost_of_the_call: "service gratuit + coût de l'appel",
    or_by_email_at: 'ou par email à',
    do_you_really_want_to_cancel_order: 'Voulez-vous vraiment annuler votre commande ?',
    do_you_really_want_to_refuse_quotation: 'Voulez-vous vraiment refuser le devis ?',
    yes: 'oui',
    no: 'non',
    am: 'le matin',
    am_short: 'Matin',
    pm: "l'après-midi",
    pm_short: 'Après-midi',
    evening: 'en soirée',
    evening_short: 'Soirée',
    order_cancelled: 'Commande annulée',
    no_trader_found_title:
      "Nous avons le regret de vous annoncer que nous n'avons trouvé aucun prestataire disponible à proximité de chez vous pour prendre en charge votre problème",
    trader_found: 'Un prestataire a été trouvé',
    trader_found_long:
      "Notre prestataire va prendre contact avec vous afin de confirmer les modalités de l'intervention",
    job_planned: 'Votre intervention est planifiée',
    trader: 'prestataire',
    contact: 'contact',
    job_in_progress: 'Intervention en cours',
    your_job_is_in_progress: 'Votre intervention est en cours',
    order_cancelled_long: "Vous avez choisi d'annuler votre commande. Votre intervention a été annulée sans frais",
    quote_updated: 'Devis actualisé',
    extra_charge: 'Supplément si majoration tarifaire',
    quotation_accepted: 'Nouveau devis accepté',
    display_my_invoice: 'Télécharger ma facture',
    order_payment: 'Intervention cloturée',
    rate_your_trader: 'Évaluer mon professionnel',
    job_done: 'Intervention terminée',
    trader_start_moving: 'Prestataire en route',
    new_quote_refused: 'Nouveau devis refusé',
    your_trader_updated_the_quote_less_money:
      'Votre professionnel a  modifié le créneau horaire entraînant une baisse du montant du devis',
    your_trader_updated_the_quote: 'Votre professionnel a validé le créneau horaire',
    cancel_reason: "Raison d'annulation",
    your_trader_has_canceled: "Votre professionnel a annulé l'intervention",
    your_trader_has_canceled_the_job_planned_on: "Votre professionnel a annulé l'intervention prévue le ",
    cause: 'Cause',
    another_trader_will_take_the_job: 'Un autre professionnel va vous contacter pour fixer un nouveau rendez-vous',
    order_review: "Suivi d'intervention",
    hello: 'Bonjour',
    here_is_your_order_review: "Voici votre suivi d'intervention",
    validate: 'Valider',
    download_my_quote: 'Télécharger mon devis',
    order_cancelled_no_show: `Votre intervention a été annulée, en accord avec nos CGV, nous allons procéder au prélèvement d'un montant forfaitaire pour couvrir le déplacement de notre prestataire`,
    cancelation_fees_payment: `Paiement des frais d'annulation`,
    you_were_absent: `Vous n'étiez pas là le jour de l'intervention`,
  },
  plomberie: 'plomberie',
  électricité: 'électricité',
  electricite: 'électricité',
  gaz: 'gaz',
};
